
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import HeaderBar from "@/components/UI/HeaderBar/UIHeaderBar.vue";
import Schedule from "@/components/Checkout/Schedule.vue";
import Receipt from "@/components/Checkout/Receipt.vue";
import UIButton from "@/components/UI/Button/UIButton.vue";
import Vue from "vue";
import UITitle from "@/components/UI/Text/UITitle.vue";
import CheckoutSubtitle from "@/components/Checkout/UICheckoutSubtitle.vue";
import { ImageSettings } from "@/Settings/Settings";
import Card from "@/components/UI/Card/CardRounded.vue";
import UISubtitle from "@/components/UI/Text/UISubtitle.vue";
import PartnerSettings from "@/models/PartnerSettings";
import StoreSettings from "@/models/StoreSettings";
import OrderType from "@/models/OrderType";
import PaymentMethodForm from "@/components/Checkout/PaymentMethodForm.vue";
import PaymentCurrencyForm from "@/components/Checkout/PaymentCurrencyForm.vue";
import PaymentMethod from "@/models/PaymentMethod";
import User from "@/models/User";
import UIMoneyFormat from "@/components/UI/Money/UIMoneyFormat.vue";
import Cart from "@/models/Cart";
import CheckoutMainCardComponent from "@/components/Checkout/CheckoutMainCardComponent.vue";
import routerManager from "@/routerManagerNew/routerManager";
import MainDialogCard from "@/components/MainDialogComponents/MainDialogCard.vue";
import WsaTextField from "@/components/UI/TextFields/WsaTextField.vue";
import WsaButton from "@/components/UI/Button/wsa-button.vue";
import PromoCodeDialog from "@/components/Checkout/PromoCodeDialog.vue";
import SpecialRequestDialog from "@/components/Checkout/SpecialRequestDialog.vue";
import Currency from "@/models/Currency";
import _requestHelper from "@/apiManager/_requestHelper";
import { EPartnerStoresStructureType } from "@/enums/EPartnerStoresStructureType";
import OnlinePaymentMethod from "@/models/OnlinePaymentMethod";
import { logMetaPixelEvent } from "@/metaPixel/LogMetaPixelEvent";


export default Vue.extend({
  components: {
    "header-bar": HeaderBar,
    "ui-button": UIButton,
    schedule: Schedule,
    receipt: Receipt,
    "ui-title": UITitle,
    "checkout-subtitle": CheckoutSubtitle,
    card: Card,
    "ui-subtitle": UISubtitle,
    "payment-method-form": PaymentMethodForm,
    "payment-currency-form": PaymentCurrencyForm,
    "ui-money-format": UIMoneyFormat,
    "checkout-main-card-component": CheckoutMainCardComponent,
    MainDialogCard,
    WsaTextField,
    "wsa-button": WsaButton,
    PromoCodeDialog,
    SpecialRequestDialog
  },

  metaInfo: function() {
    return {
        title: "Checkout",
    }
  },

  data: () => ({
    selectedDay: 0 as number,
    slotsOfDay: [] as any[],
    step: 0 as number,
    promoCodeValue: "" as string,

    // image url
    itemsImageUrl: ImageSettings.baseItemsUrl as string,

    /** Selected schedule slot */
    selectedSlotFromDialog: null as any,
    chosenSlot: null as any,

    /** Address information */
    additionalAddressInformation: "" as string,

    /** Special Request */
    specialRequest: "" as string,

    /** order types int */
    deliveryOrderTypeInt: OrderType.deliveryOrderTypeInt,
    pickupOrderTypeInt: OrderType.pickupOrderTypeInt,

    /** Location Dialog */
    dialog: false as boolean,

    /** payment method Dialog*/
    paymentMethodDialog: false as boolean,
    selectedPaymentMethod: {} as PaymentMethod,
    chosenPaymentMethod: {} as PaymentMethod,
    paymentCurrencyDialog: false as boolean,
    chosenPaymentCurrency: {id: 0 } as Currency,
    
    scheduleDialog: false as boolean,
    promoCodeDialog: false as boolean,
    specialRequestDialog: false as boolean,

    payUsingEwallet: false as boolean,
    amountPaidByEWallet: 0 as number,

    payUsingLoyalty: false as boolean,
    amountPaidByLoyalty: 0 as number,

    remainingToBePaid: 0 as number,

    rule: [
      (v: string) =>
        (typeof v != "undefined" && v.trim() != "") || "This field is required",
    ],

    convertedTotal: null as any | null,

    listOfOnlinePaymentMethods: [] as OnlinePaymentMethod[],
    selectedOnlinePaymentMethodToPayWith: {} as OnlinePaymentMethod,
    initiateCheckoutFormLoading: false as boolean,
    itemsServicingFees: 0 as number,
  }),

  computed: {
    ...mapState("schedule", ["firstAvailableSlot",]),
    ...mapState("order", ["promoCodeValidation", "isPromoCodeValidated", "newOrderPlacedObject", "serviceFeesInclTax", "serviceFeesTaxValue"]),
    ...mapState("store", ["selectedStoreDetails"]),
    ...mapState("partner", ["partnerSettings", "appDataContent"]),
    ...mapState("loading", ["placeOrderLoading"]),
    ...mapState("ui", ["isMobileViewDisplayed"]),
    ...mapState("eWallet", ["eWallet"]),
    ...mapState("loyalty", ["loyalty"]),
    ...mapState("auth", ["preferredOrderType"]),


    ...mapGetters("auth", ["currentSelectedUser"]),
    ...mapGetters("cart", ["currentSelectedCart", "currentSelectedCartTotalAmount", "currentSelectedCartTotalDRSAmount","currentSelectedCartTotalEBRDAmount", "currentSelectedCartTotalQuantity"]),

    user(): User {
      return (this as any).currentSelectedUser as User
    },
  
    // generated GUID
    tempId(): string {
      return (this as any).createUUID();
    },

    storeOrPartnerNameDependingOnStoreStruct(): string {
      if((this.partnerSettings as PartnerSettings).partnerStoresStructureType == EPartnerStoresStructureType.StoreAgnosticModel && (this as any).preferredOrderType.typeInt == OrderType.deliveryOrderTypeInt) {
        return (this.partnerSettings as PartnerSettings).partnerName
      }
      else {
        return this.selectedStoreDetails.displayName
      }
    },

    isOtherPaymentMethodsEnabled(): boolean {
      return ((this as any).partnerSettings.isLoyaltyProgramEnabled == true && (this as any).partnerSettings.isPartnerLoyaltyPaymentFeatureEnabled == true && typeof (this as any).loyalty != 'undefined' && this.loyalty.creditsInCash > 0)
        || (this as any).partnerSettings.isEWalletFeatureEnabled == true && (this as any).partnerSettings.isEWalletPaymentFeatureEnabled == true && (this as any).eWallet && (this as any).eWallet.balance > 0      
    }
  },

  methods: {
    ...mapActions("order", ["place", "validatePromoCode"]),
    ...mapActions("schedule", ["getFirstAvailableSlot"]),
    ...mapActions("cart", ["emptyCurrentCart"]),

    ...mapMutations("order", ["CLEAR_PROMOTION"]),

    /** form validation */
    validateForm(): boolean {
      return ((this as any).$refs.checkoutForm as any).validate();
    },

    /** check if customer is eligible for delivery order type */
    isCustomerEligibleForDelivery(): boolean {
      return ((this as any).partnerSettings as PartnerSettings).isDeliveryFeatureEnabled 
        && ((this as any).selectedStoreDetails as StoreSettings).hasDeliveryService
        && ((this as any).selectedStoreDetails as StoreSettings).deliveryZone && ((this as any).selectedStoreDetails as StoreSettings).deliveryZone.isCustomerEligibleForDelivery;
    },

    /** check if customer is eligible for pickup order type */
    isCustomerEligibleForPickup(): boolean {
      return ((this as any).partnerSettings as PartnerSettings).isPickupFeatureEnabled 
        && ((this as any).selectedStoreDetails as StoreSettings).hasPickupService
    },

    /** get order type from int */
    getOrderTypeFromInt(int: number): OrderType {
      return OrderType.getOrderTypeFromInt(int);
    },

    /** Generate GUID  */
    createUUID(): string {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },

    /** get the first available slot by order type */
    getFirstSlot() {
      (this as any).getFirstAvailableSlot({
        branchId: ((this as any).selectedStoreDetails as StoreSettings).id,
        orderType: (this as any).preferredOrderType.typeInt,
        address: {
          city: (this as any).user.usedAddress.city,
          area: (this as any).user.usedAddress.area,
          tag:  (this as any).user.usedAddress.tag.id,
          coordinatesLongitude:  (this as any).user.usedAddress.position.longitude,
          coordinatesLatitude:  (this as any).user.usedAddress.position.latitude
        },
        items: ((this as any).currentSelectedCart as Cart).items,
        userDeliveryZoneId: ((this as any).selectedStoreDetails as StoreSettings).deliveryZone.id,
        userPickupZoneId: ((this as any).selectedStoreDetails as StoreSettings).pickupZone.id,
      }).then(() => {
        (this as any).chosenSlot = (this as any).firstAvailableSlot;
      })
    },

    /** set the default order payment method */
    setDefaultPaymentMethod() {
      var availableListOfPaymentMethods = PaymentMethod.getListOfAvailablePaymentMethodsByOrderType((this as any).preferredOrderType.typeInt);
      
      if(availableListOfPaymentMethods.length > 0) {
        (this as any).chosenPaymentMethod = availableListOfPaymentMethods[0];

        var availableStoreCurrency = (this as any).chosenPaymentMethod.availableCurrencies.find((x: any) => x.id == ((this as any).selectedStoreDetails as StoreSettings).defaultCurrency.id);
        if(typeof availableStoreCurrency !== 'undefined' && availableStoreCurrency != null) {
          (this as any).chosenPaymentCurrency = availableStoreCurrency;
        }
        else {
          (this as any).chosenPaymentCurrency = (this as any).chosenPaymentMethod.availableCurrencies[0];
        }
      }
    },

    updateUserCompleteAddress(info: string) {
      (this as any).$store.commit("auth/UPDATE_USER_COMPLETE_ADDRESS", info);
    },

    /** if user has free delivery */
    isUserHasFreeDelivery(): boolean {   
      var result = false;
      var zone = ((this as any).selectedStoreDetails as StoreSettings).deliveryZone;
      

      if((this as any).isCustomerEligibleForDelivery() == true) {
        
        if(zone.hasFreeDelivery == false) {
          result = false;
        }

        else if((this as any).currentSelectedCartTotalAmount >= ((this as any).selectedStoreDetails as StoreSettings).deliveryZone.freeDeliveryThreshold) {
          return true;
        }

      }
      return result;
    },

    /** get additional fees: service fees + delivery fees */
    getAdditionalFees(): number {
      var additionalFees = 0;

      // add service fees
      additionalFees += (this as any).serviceFeesInclTax;
      additionalFees += (this as any).itemsServicingFees;

      // if order type is delivery
      if((this as any).isUserHasFreeDelivery() == false && (this as any).isCustomerEligibleForDelivery() == true && (this as any).preferredOrderType.typeInt == this.deliveryOrderTypeInt) {    
        additionalFees += ((this as any).selectedStoreDetails as StoreSettings).deliveryZone.deliveryFees; // add delivery fees
      }

      return additionalFees;
    },

    getOrderVAT(): number {
      let vat = 0;
      for (let i = 0; i < (this as any).currentSelectedCart.items.length; i++)
        vat += (this as any).currentSelectedCart.items[i].quantity * (this as any).currentSelectedCart.items[i].adjVATValue;
        
      return vat;
    },

    getOrderTotalAmount(): number {
      return (this as any).currentSelectedCartTotalAmount + (this as any).currentSelectedCartTotalDRSAmount + (this as any).currentSelectedCartTotalEBRDAmount + + (this as any).getAdditionalFees() - ((this as any).isPromoCodeValidated == true ? (this as any).promoCodeValidation.discountAmount : 0)
    },

    generateReceipt(): any {
      return {
        receiptVersion: 1,
        subtotal: (this as any).currentSelectedCartTotalAmount,
        vatTotal: (this as any).getOrderVAT(),
        deliveryFees: (this as any).preferredOrderType.typeInt == OrderType.pickupOrderTypeInt 
          ? 0 
          : (this as any).isUserHasFreeDelivery() ? 0 : ((this as any).selectedStoreDetails as StoreSettings).deliveryZone.deliveryFees,          
        serviceFees: (this as any).serviceFeesInclTax,
        serviceFeesTaxValue: (this as any).serviceFeesTaxValue,
        discounts: (this as any).promoCodeValidation.discountAmount ? (this as any).promoCodeValidation.discountAmount : 0,
        totalComputedAmount: (this as any).getOrderTotalAmount(),
        itemsServicingFees: this.itemsServicingFees,
        totalAmountForWeightedItems: null,
        totalAmountForNonWeightedItems: null,
        minFinalAdjTotal: null,
        maxFinalAdjTotal: null,
        dueToWeightChange: null,
        adjustedTotal: (this as any).getOrderTotalAmount(),
        totalNumberOfItems: (this as any).currentSelectedCartTotalQuantity,
        currencyId: (this as any).chosenPaymentCurrency.id,
        drsAmount: (this as any).currentSelectedCartTotalDRSAmount,
        ebrdAmount: (this as any).currentSelectedCartTotalEBRDAmount,
        currencyForPaymentRate: ((this as any).selectedStoreDetails as StoreSettings).defaultCurrency.currencyCode == 'USD' 
          ? null 
          : (this as any).convertedTotal == null ? null : (this as any).convertedTotal.rate,

        totalAmountToBePaid: ((this as any).selectedStoreDetails as StoreSettings).defaultCurrency.currencyCode == 'USD' 
          ? null 
          : (this as any).convertedTotal == null ? null : (this as any).convertedTotal.amount,

        remainingToBePaidByCustomer: this.payUsingEwallet == true 
          ? this.remainingToBePaid 
          : null
      };
    },

    generateUserAddressObject(): any {
      return {
        tag: 0,
        id: ((this as any).user as User).usedAddress?.id == null ? 0 : ((this as any).user as User).usedAddress?.id,
        streetNumber: ((this as any).user as User).usedAddress?.streetNbre == null ? "" : ((this as any).user as User).usedAddress?.streetNbre,
        streetName: ((this as any).user as User).usedAddress?.streetName == null ? "" : ((this as any).user as User).usedAddress?.streetName,
        postalCode: ((this as any).user as User).usedAddress?.postalCode == null ? "" : ((this as any).user as User).usedAddress?.postalCode,
        city: ((this as any).user as User).usedAddress?.city == null ? "" : ((this as any).user as User).usedAddress?.city,
        area: ((this as any).user as User).usedAddress?.area == null ? "" : ((this as any).user as User).usedAddress?.area,
        state: ((this as any).user as User).usedAddress?.state == null ? "" : ((this as any).user as User).usedAddress?.state,
        country: ((this as any).user as User).usedAddress?.country == null ? "" : ((this as any).user as User).usedAddress?.country,
        county: ((this as any).user as User).usedAddress?.area == null ? "" : ((this as any).user as User).usedAddress?.area,
        district: ((this as any).user as User).usedAddress?.district == null ? "" : ((this as any).user as User).usedAddress?.district,
        countryCode: ((this as any).user as User).usedAddress?.countryCode == null ? "" : ((this as any).user as User).usedAddress?.countryCode,
        label: ((this as any).user as User).usedAddress?.label == null ? "" : ((this as any).user as User).usedAddress?.label ,
        additionalAddressInformation: ((this as any).user as User).usedAddress?.additionalAddressInfo == null ? "" : ((this as any).user as User).usedAddress?.additionalAddressInfo,
        coordinatesLongitude: ((this as any).user as User).usedAddress?.position.longitude == null ? "" : ((this as any).user as User).usedAddress?.position.longitude,
        coordinatesLatitude: ((this as any).user as User).usedAddress?.position.latitude == null ? "" : ((this as any).user as User).usedAddress?.position.latitude,
        houseNumber: "",      
      };
    },

    // special request submission
    onSpecialRequestSubmission(specialRequst: string) {
      (this as any).specialRequest = specialRequst.trim();
      (this as any).specialRequestDialog = false;
    },

    // validate promocode
    async validatePromotion() {
      if ((this as any).promoCodeValue.trim().length > 0) {
        await (this as any).validatePromoCode({
          promoCodeValue: (this as any).promoCodeValue,
          receipt: (this as any).generateReceipt(),
          branchId: (this as any).selectedStoreDetails.id,
          orderId: null,
          currencyCode: ((this as any).selectedStoreDetails as StoreSettings).defaultCurrency.currencyCode,
          shopperCoordinates: {
            latitude: ((this as any).user as User).usedAddress?.position.latitude,
            longitude: ((this as any).user as User).usedAddress?.position.longitude
          },
          orderType: (this as any).preferredOrderType.typeInt,
          itemsInPC: ((this as any).currentSelectedCart as Cart).items.map( item => {
            return {
              quantity: item.quantity,
              itemId: item.itemId,
              price: item.price,
              adjPriceValue: item.adjPrice
            }
          }),
        });
      }
    },

    async onPromoValidation(promoValue: string) {      
      if((this as any).isPromoCodeValidated == true) {
        (this as any).promoCodeValue = promoValue;
        (this as any).promoCodeDialog = false;

      }
      else {
        (this as any).promoCodeValue = "";
      }
      
      await this.convertAmountToChosenCurrency(this.chosenPaymentCurrency)
    },

    async removePromotion() {
      (this as any).CLEAR_PROMOTION();
      (this as any).promoCodeValue = "";
      await this.convertAmountToChosenCurrency(this.chosenPaymentCurrency)

    },

    removeSpecialRequest() {
      (this as any).specialRequest = "";
    },

    async convertAmountToChosenCurrency(chosenCurrency: Currency){
      return _requestHelper({
        method: "POST",
        data: {
          fromCurrencyId: ((this as any).selectedStoreDetails as StoreSettings).defaultCurrency.id,
          branchId: ((this as any).selectedStoreDetails as StoreSettings).id,
          toCurrencyCode: chosenCurrency.currencyCode,
          amount: (this as any).getOrderTotalAmount()
        },
        loaderKey: "loa",
        url:"currency/convertRate",
        successCallback: (convertedTotal: any) => {
          (this as any).convertedTotal = convertedTotal
        }
      })
    },

    /** Place Order  */
    async placeOrder() {
      if((this as any).validateForm() == true) {
        await (this as any).validatePromotion();

        // if user is using a promo code and it is not validated
        if ((this as any).promoCodeValue.trim().length > 0 && (this as any).isPromoCodeValidated == false)
          return;
        
        
        // if the payment method is online payment and no cart selected
        // throw an error
        if(this.chosenPaymentMethod.pMethodInt == PaymentMethod.OnlieCardPayment && (this.selectedOnlinePaymentMethodToPayWith == null ||  !this.selectedOnlinePaymentMethodToPayWith.id || this.selectedOnlinePaymentMethodToPayWith.id == 0)) {
          this.$store.commit("ui/snackbar", {
              show: true,
              error: true,
              message: "Please select a payment card to proceed with the order"
            });

          return;
        }


        // place order
        (this as any).place({
          tempId: (this as any).tempId,
          branchId: (this as any).selectedStoreDetails.id,
          orderType: (this as any).preferredOrderType.typeInt,
          paymentMethod: (this as any).chosenPaymentMethod.pMethodInt,
          slotId: ((this as any).chosenSlot as any).id,
          specialRequest: (this as any).specialRequest && (this as any).specialRequest.trim().length > 0 ? (this as any).specialRequest : "none",
          items: ((this as any).currentSelectedCart as Cart).items,
          address: (this as any).generateUserAddressObject(),
          receipt: (this as any).generateReceipt(),
          promoCodeValue: (this as any).promoCodeValue,
          currencyId: ((this as any).selectedStoreDetails as StoreSettings).defaultCurrency.id,
          userDeliveryZoneId: ((this as any).selectedStoreDetails as StoreSettings).deliveryZone.id,
          userPickupZoneId: ((this as any).selectedStoreDetails as StoreSettings).pickupZone.id,
          createdFrom: 1,
          payUsingEWallet: this.payUsingEwallet,
          orderAlternativesFlavor: 3, // discard alternatives
          eWalletPayment: 
            typeof (this as any).eWallet != "undefined" && typeof (this as any).eWallet.currency != "undefined"
              ? {
                  walletId: (this as any).eWallet.id,
                  currencyId: (this as any).eWallet.currency.id,
                  amount: this.amountPaidByEWallet
                }
              : null,

          payUsingPartnerLoyalty: this.payUsingLoyalty,
          partnerLoyaltyPayment: 
            typeof ((this as any).partnerSettings as PartnerSettings).defaultPartnerLoyaltyCurrency == "undefined"
            || ((this as any).partnerSettings as PartnerSettings).defaultPartnerLoyaltyCurrency == null
            ? null
            : {
                amount: this.amountPaidByLoyalty,
                currencyId: ((this as any).partnerSettings as PartnerSettings).defaultPartnerLoyaltyCurrency.id
              },
              
          onlineCardId: (this as any).chosenPaymentMethod.pMethodInt == PaymentMethod.OnlieCardPayment ? this.selectedOnlinePaymentMethodToPayWith.id : null
        }).then(() => {
          // if order is placed clear cart and go to order summary
          this.emptyCurrentCart();
          (this as any).CLEAR_PROMOTION();
          
          logMetaPixelEvent('purchase');

          routerManager.goToOrderSummary((this as any).newOrderPlacedObject.id);     
        });
      }

      else {
        if(((this as any).user as User).usedAddress?.additionalAddressInfo == "" || ((this as any).user as User).usedAddress?.additionalAddressInfo == null ) {
          this.$store.commit("ui/snackbar", {
            show: true,
            error: true,
            message: this.appDataContent.completeAddressIsRequired
          })
        }
      }
    },

    async getItemsServicingFees(){ 
      var body = {
        items: [] as any,
        branchId:  (this as any).selectedStoreDetails.id,
        currencyCode: ((this as any).selectedStoreDetails as StoreSettings).defaultCurrency.currencyCode
      };

      var items = ((this as any).currentSelectedCart as Cart).items.map( item => {
        return {
          quantity: item.quantity,
          id: item.itemId,        
        }
      });

      body.items = items;

      await _requestHelper({
        method: "post",
        url: "orders/sa/item-servicing-fees",
        data: body,
        loaderKey: "",
        successCallback: (res: any) => {
          this.itemsServicingFees = res.itemsServicingFees;
        }
      });
    }
    
 
 },

  watch: { 
    orderType() {
      (this as any).getFirstSlot();
      (this as any).setDefaultPaymentMethod();
    },

    selectedSlotFromDialog(newVal) {
     (this as any).chosenSlot = newVal; 
    },

    selectedPaymentMethod(newVal) {
      (this as any).chosenPaymentMethod = newVal; 

      this.selectedOnlinePaymentMethodToPayWith = {} as OnlinePaymentMethod;

      // set by default the same store currency if available
      // if not, set the first available one
      var availableStoreCurrency = (this as any).chosenPaymentMethod.availableCurrencies.find((x: any) => x.id == ((this as any).selectedStoreDetails as StoreSettings).defaultCurrency.id);
      if(typeof availableStoreCurrency !== 'undefined' && availableStoreCurrency != null) {
        (this as any).chosenPaymentCurrency = availableStoreCurrency;
      }
      else {
        (this as any).chosenPaymentCurrency = (this as any).chosenPaymentMethod.availableCurrencies[0];
      }
    },

    // convert total amount to the selected currency
    // if the selected currency is different of store default currency
    chosenPaymentCurrency(newVal: Currency) {
      if(typeof newVal != 'undefined') {
        if(newVal.id != ((this as any).selectedStoreDetails as StoreSettings).defaultCurrency.id) {
          this.convertAmountToChosenCurrency(newVal)
        }

        else {
          (this as any).convertedTotal = null;
        }
      }
    },

    payUsingLoyalty(value: boolean) {
      if(value == false) {
        this.amountPaidByLoyalty = 0;
      }

      else {
        this.payUsingEwallet = false;

        var orderAmount = this.getOrderTotalAmount();

        if((this as any).loyalty.creditsInCash > orderAmount) {
          this.amountPaidByLoyalty = orderAmount;
        }

        else {
          this.amountPaidByLoyalty = (this as any).loyalty.creditsInCash;
        }

        this.remainingToBePaid = orderAmount - this.amountPaidByLoyalty
      }
    },

    payUsingEwallet(value: boolean) {
      if(value == false) {
        this.amountPaidByEWallet = 0;
      }

      else {
        this.payUsingLoyalty = false;
        
        var orderAmount = this.getOrderTotalAmount();

        if((this as any).eWallet.balance > orderAmount) {
          this.amountPaidByEWallet = orderAmount;
        }

        else {
          this.amountPaidByEWallet = (this as any).eWallet.balance;
        }

        this.remainingToBePaid = orderAmount - this.amountPaidByEWallet
      }
    }

  },

  async mounted() {
    (this as any).getFirstSlot();
    await (this as any).getItemsServicingFees();
    (this as any).setDefaultPaymentMethod();
    (this as any).CLEAR_PROMOTION();

    if (typeof (this as any).$refs.checkoutForm != "undefined") {
      ((this as any).$refs.checkoutForm as any).resetValidation();
    }

    logMetaPixelEvent('begin_checkout');
    
  }
});
