export function logMetaPixelEvent(eventName: string, eventParams?: Record<string, any>): void {
    if (typeof window.fbq !== "undefined") {
      try {
        window.fbq('track', eventName, eventParams || {});
        console.log(`Event '${eventName}' logged with parameters:`, eventParams);
      } catch (error) {
        console.error(`Error logging event '${eventName}':`, error);
      }
    } else {
      console.warn('Meta Pixel is not available.');
    }
}


export {};
