var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.isDepartmentsHighestLayer == false)?_c('div',{staticClass:"pb-0"},[(_vm.listOfCategoriesLoading)?_vm._l((15),function(i){return _c('v-skeleton-loader',{key:i,attrs:{"type":"list-item"}})}):[(!_vm.isLowestLayerSubcategory)?[_c('v-list',{staticClass:"py-0"},_vm._l((_vm.categoriesAndSubCatList),function(category){return _c('span',{key:category.id,class:{'active': _vm.$route.params.categoryId == category.id}},[_c('v-list-group',{staticClass:"my-0",staticStyle:{"border-radius":"10px"},attrs:{"dense":"","no-action":""},scopedSlots:_vm._u([{key:"appendIcon",fn:function(){return [_c('v-icon',{staticClass:"d-none",attrs:{"small":""}},[_vm._v("mdi-chevron-down")])]},proxy:true},{key:"activator",fn:function(){return [_c('v-list-item-content',{staticClass:"px-2 py-3 categories-height"},[_c('v-list-item-title',{staticClass:"poppins-bold black--text ps-2",staticStyle:{"font-size":"12.5px"}},[_vm._v(" "+_vm._s(category.name)+" ")])],1)]},proxy:true}],null,true)},_vm._l((category.subcatList),function(subcat){return _c('v-list-item',{key:subcat.id,staticClass:"text-start",attrs:{"to":subcat.isAlcohol || subcat.isTobacco 
                                            ? null
                                            : {
                                                name: 'productsPageBySubCategory',
                                                params: {
                                                    categoryId: category.id,
                                                    categoryName: category.name,
                                                    subcategoryId: subcat.id,
                                                    subcategoryName: subcat.name,
                                                    title: subcat.name,
                                                    itemsCollectionId: subcat.itemsCollectionId,
                                                    itemsCollectionType: subcat.itemsCollectionType,
                                                }
                                            },"dense":"","active-class":"primary--text"},on:{"click":function($event){return _vm.proceed(subcat, false,{ name: 'productsPageBySubCategory',
                                            params: {
                                                categoryId: category.id,
                                                categoryName: category.name,
                                                subcategoryId: subcat.id,
                                                subcategoryName: subcat.name,
                                                title: subcat.name,
                                                itemsCollectionId: subcat.itemsCollectionId,
                                                itemsCollectionType: subcat.itemsCollectionType,
                                            }
                                        })}}},[_c('v-list-item-content',{staticClass:"py-0 mt-0",class:_vm.$route.params.subcategoryId == subcat.id ? 'primary--text' : ''},[_c('v-list-item-title',{staticClass:"d-flex align-center me-2 ps-5",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(subcat.name)+" ")])],1)],1)}),1)],1)}),0)]:_vm._l((_vm.categoriesAndSubCatList),function(category,i){return _c('v-list-item',{key:i,staticClass:"pt-0 hover",class:`${ _vm.$route.params.categoryId == category.id ? 'active-category' : ''}`,staticStyle:{"color":"black"},attrs:{"to":category.isAlcohol || category.isTobacco 
                                ? null
                                : {
                                    name: 'productsPageByCategory',
                                    params: {
                                        categoryId: category.id,
                                        categoryName: category.name,
                                        title: category.name,
                                        campaignId: category.campaignId,
                                        campaignCollectionId:  category.isCustomCategory == true ? category.id : 0,
                                        itemsCollectionType: category.isCustomCategory == true ? category.itemsCollectionType : 0
                                    },
                                    query: { 
                                        type: category.isCustomCategory == true ? category.itemsCollectionType : 0,
                                        typeId: category.isCustomCategory == true ? category.id : 0,
                                    }
                                }},on:{"click":function($event){return _vm.proceed(category, false,{
                                name: 'productsPageByCategory',
                                params: {
                                    categoryId: category.id,
                                    categoryName: category.name,
                                    title: category.name,
                                    campaignId: category.campaignId,
                                    campaignCollectionId:  category.isCustomCategory == true ? category.id : 0,
                                    itemsCollectionType: category.isCustomCategory == true ? category.itemsCollectionType : 0
                                },
                                query: { 
                                    type: category.isCustomCategory == true ? category.itemsCollectionType : 0,
                                    typeId: category.isCustomCategory == true ? category.id : 0,
                                }
                            })}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"poppins-semi-bold",class:`${_vm.$vuetify.breakpoint.mobile ? 'py-2' : ''}`,staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(category.name)+" ")])],1)],1)})]],2):_c('div',{staticClass:"mb-0"},[_c('v-list',{staticClass:"py-0"},_vm._l((_vm.departmentsList),function(department){return _c('span',{key:department.id,class:{'active': _vm.$route.params.departmentId == department.id}},[_c('v-list-group',{staticClass:"my-0",staticStyle:{"border-radius":"10px"},attrs:{"dense":"","no-action":""},scopedSlots:_vm._u([{key:"appendIcon",fn:function(){return [_c('v-icon',{staticClass:"d-none",attrs:{"small":""}},[_vm._v("mdi-chevron-down")])]},proxy:true},{key:"activator",fn:function(){return [_c('v-list-item-content',{staticClass:"px-2 py-3 categories-height"},[_c('v-list-item-title',{staticClass:"poppins-bold black--text ps-2",staticStyle:{"font-size":"12.5px"}},[_vm._v(" "+_vm._s(department.name)+" ")])],1)]},proxy:true}],null,true)},_vm._l((department.categoriesList),function(category){return _c('v-list-item',{key:category.id,staticClass:"text-start",attrs:{"to":category.isAlcohol || category.isTobacco 
                                ? ''
                                : {
                                        name: 'productsPageByCategoryByDepartment',
                                        params: {
                                            categoryId: category.id,
                                            categoryName: category.name,
                                            departmentId: department.id,
                                            departmentName: department.name,
                                            title: department.name
                                        }
                                    },"dense":"","active-class":"primary--text"},on:{"click":function($event){return _vm.proceed(category, false,{
                                name: 'productsPageByCategoryByDepartment',
                                params: {
                                    categoryId: category.id,
                                    categoryName: category.name,
                                    departmentId: department.id,
                                    departmentName: department.name,
                                    title: department.name
                                }
                            })}}},[_c('v-list-item-content',{staticClass:"py-0 mt-0",class:_vm.$route.params.categoryId == category.id ? 'primary--text' : ''},[_c('v-list-item-title',{staticClass:"d-flex align-center me-2 ps-5",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(category.name)+" ")])],1)],1)}),1)],1)}),0)],1),_c('alcohol-warning-dialog',{on:{"onProceed":function($event){return _vm.proceed(_vm.selectedElement, true, _vm.selectedElementRoute)}},model:{value:(_vm.alcoholWarningDialog),callback:function ($$v) {_vm.alcoholWarningDialog=$$v},expression:"alcoholWarningDialog"}}),_c('tobacco-warning-dialog',{on:{"onProceed":function($event){return _vm.proceed(_vm.selectedElement, true, _vm.selectedElementRoute)}},model:{value:(_vm.tobaccoWarningDialog),callback:function ($$v) {_vm.tobaccoWarningDialog=$$v},expression:"tobaccoWarningDialog"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }