
import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import Cart from "@/models/Cart";
import Product, { WeightedProductUnitMeasure } from "@/models/Product";
import PartnerSettings from "@/models/PartnerSettings";
import QuantityInCart from "./QuantityInCart.vue";
import IncreaseItemCart from "./IncreaseItemCart.vue";
import DecreaseItemCart from "./DecreaseItemCart.vue";
import WsaButton from "@/components/UI/Button/wsa-button.vue";
import OrderType from "@/models/OrderType";
import User from "@/models/User";
import NotEligibleDialog from '@/components/Store/NotEligibleDialog.vue';
import StoreSettings from "@/models/StoreSettings";
import { logMetaPixelEvent } from "@/metaPixel/LogMetaPixelEvent";

export default Vue.extend({
  components: { 
    "quantity-in-cart": QuantityInCart,
    "increase-item-cart": IncreaseItemCart,
    "decrease-item-cart": DecreaseItemCart,
    "wsa-button": WsaButton,
    "not-eligible-dialog": NotEligibleDialog
  },

  props: {
    item: {
      required: true,
      type: Object,
    },

    isFromItemCard: {
      default: true,
      required: false,
      type: Boolean
    },

    isFromCart: {
      default: false,
      required: false,
      type: Boolean
    },

    isFromItemDetails: {
      default: false,
      required: false,
      type: Boolean
    }
  },

  data: () => ({
    isActionExpanded: false as boolean,
    eligibilityCheckModel: false as boolean,
  }),

  computed: {
    ...mapState("partner", ["partnerSettings"]),
    ...mapState("store", ["selectedStoreDetails"]),
    ...mapState("auth", ["preferredOrderType"]),
    ...mapGetters("cart", ["currentSelectedCart", "currentSelectedCartTotalAmount", "currentSelectedCartTotalQuantity"]),
    ...mapGetters("auth", ["currentSelectedUser"]),
    ...mapState("ui", ["isMobileViewDisplayed"]),


    user(): User {
      return (this as any).currentSelectedUser
    },

    isChosenOrderTypeUnknown(): boolean {
        return this.preferredOrderType.typeInt == OrderType.unknowOrderTypeInt
    },

    
    itemQuantityInCartToBeDisplayedInItemDetails(): string {
      var cart: Cart = (this as any).currentSelectedCart;

      var itemFoundInCart = cart.items.find(i => i.id == this.item.id);

      var itemQuantityInCart = 0;

      if(typeof itemFoundInCart != "undefined" && itemFoundInCart != null) {
        itemQuantityInCart = itemFoundInCart?.quantity

        if(itemQuantityInCart > 0) {
          
          if(itemFoundInCart.isWeightSensitive == true) {
              return WeightedProductUnitMeasure.getTotalWeightValueInCorrectUnit(itemFoundInCart.unitMeasure * itemQuantityInCart, itemFoundInCart.unitMeasureCode)
          }

          // by piece
          return itemQuantityInCart.toString();
        }
      }

      return '';
    },

  },

  methods: {
    ...mapActions("cart", ["addItemToCart", "increaseItemInCart", "decreaseItemInCart"]),
    
    checkIfItemInCart(item: Product): boolean {
      var cart: Cart = (this as any).currentSelectedCart;

      var itemFoundInCart = cart.items.find(i => i.id == item.id);
      return typeof itemFoundInCart != "undefined" && itemFoundInCart != null;
    },

    checkItemQuantityInCart(): number {
      var cart: Cart = (this as any).currentSelectedCart;

      var itemFoundInCart = cart.items.find(i => i.id == this.item.id);

      if(typeof itemFoundInCart != "undefined" && itemFoundInCart != null)
        return itemFoundInCart?.quantity;
      
      return 0;
    },

    increaseQuantity(item: Product) {

      if(this.isChosenOrderTypeUnknown == true) { 
        this.eligibilityCheckModel = true
      }

      else {
        this.isActionExpanded = true;

        if(this.checkIfItemInCart(item) == false){
          
          logMetaPixelEvent('add_to_cart', 
          { 
            content_name: item.name, 
            value: item.adjPriceValue, 
            currency: ((this as any).selectedStoreDetails as StoreSettings).defaultCurrency
          });

          this.addItemToCart(item);
        }

        else {
          this.increaseItemInCart(item);
        }
      }
    },

    decreaseQuantity(item: Product) {
      this.decreaseItemInCart(item);      
      
      if(this.checkIfItemInCart(item) == false) { 
        this.$forceUpdate();
        this.isActionExpanded = false;


        logMetaPixelEvent('remove_from_cart', 
        { 
          content_name: item.name
        });
        
      }
    },

    checkIfAddBtnIsDisabled(): boolean {
      return this.item.isOutOfStock 
      || this.currentSelectedCartTotalQuantity >= (this.partnerSettings as PartnerSettings).maxNbreOfItemsInOrder
      || this.currentSelectedCartTotalAmount > (this.partnerSettings as PartnerSettings).maxOrderTotal
      || this.checkItemQuantityInCart() >= this.item.maxOrder;     
    }
  },

  watch: {
    currentSelectedCartTotalQuantity() {
      if(this.checkIfItemInCart(this.item) == false) {
        this.isActionExpanded = false;
      }
    }
  }

});
