
import Vue from 'vue'
import { mapState } from 'vuex'
import WsaButton from '../UI/Button/wsa-button.vue'
import UITitle from '../UI/Text/UITitle.vue'
import Recaptcha from "../Recaptcha.vue";

export default Vue.extend({
    props: ["authenticationOtp", "onSubmit", "onResendBtnClick", "phoneNumber", "isLight", "otpSubmissionFailed"],

    data: () => ({
        smsRetryIn: 30,
        smsCounter: 1,
        interval: 0,
        contactUsDialog: false,
        isRecaptchaVerified: false as boolean,
        resetRecaptcha: false as boolean,
    }),
    
    components: {    
        "ui-title": UITitle,
        "wsa-button": WsaButton,
        "re-captcha": Recaptcha
    },  
    
    computed: {
        ...mapState("partner", ["partnerSettings", "appDataContent"]),
        ...mapState("ui", ["isSmScreen"]),
        
        authenticationOtpModel: {
            get(): string {
                return this.authenticationOtp;
            },

            set(value: string) {
                this.$emit("changed", value);
            }
        },

        isReceptchaActive(): boolean {
            return this.smsCounter >= 3 && this.isRecaptchaVerified == false
        }
    },

    model: {
        prop: "authenticationOtp",
        event: "changed"
    },

    methods: {
        openContactUsDialog() {
            this.$emit("setContactUsPage");
        },


        onResendBtnclicked() {                
            this.smsCounter++;

            if(this.isReceptchaActive && this.isRecaptchaVerified == false)
                return;
        

            this.smsRetryIn = this.partnerSettings.smsRetryAwaitingDelayInSeconds;
            clearInterval(this.interval);
            this.setTimeIntervalToResendSms();
            this.onResendBtnClick();
        },

        setTimeIntervalToResendSms() {
            this.interval = window.setInterval(() => {
                if(this.smsRetryIn == 0) {
                    clearInterval(this.interval);
                }
                else {
                    this.smsRetryIn--;
                }
            }, 1000)
        }
    },

    watch: {
        isRecaptchaVerified(val: Boolean) {
            if(val == true){ 
                clearInterval(this.interval);
                this.setTimeIntervalToResendSms();
                this.onResendBtnClick();
                // this.deactivateRecaptcha = true;
            }
        }
    },

    mounted() {
        this.smsRetryIn = this.partnerSettings.smsRetryAwaitingDelayInSeconds;
        this.setTimeIntervalToResendSms();
    }
})
