export class Apis {
  // Sub URLs
  public static registerDeviceFCMToken = "device/register";

  // Authentication Apis
  public static loginSubUrl = "accounts/login/sa";
  public static registerSubUrl = "accounts/register";
  public static forgotPwdSubUrl = "accounts/forgot-password";
  public static resetPwdSubUrl = "accounts/reset-password";
  public static logoutSubUrl = "accounts/logout/sa";
  public static accountInfoChange = "accounts/edit";
  public static accountDeleteRequest = "accounts/delete/otp";
  public static accountDelete = "accounts/delete";

  // Cutomer Settings Apis
  public static customerSettings = "partner-settings/init";
  public static appDataContent = "partner-settings/sa/data-content";
  public static appStatusUrl = "partner-settings/sa/status";
  public static listOfDiffrentStoresURL = "branches/list";
  public static singleStoreURL = "branches/details";

  // Products Apis
  public static departmentsSubURL = "items/departments-page";
  public static categoriesSubURL = "items/categories-page";
  public static itemsSubURL = "items/items-page";
  public static itemsSearchURL = "items";
  public static itemsBulk = "items/bulk-check";
  public static itemSubURL = "items/get";
  public static itemsSimilarURL = "items/similar";
  public static itemsInformation = "items/information";
  public static brandsSubURL = "brands";

  // Banner Apis
  public static adBannerURL = "adBanner/get";
  public static adBannerOnTapURL = "adBanner/get-on-tap-content";

  // Customer Service Apis
  public static contactUsMessage = "customerservice/complaint";
  public static messageAtStoreLevelURL = "customerservice/in-app-message";

  // Order Apis
  public static placeOrderSubURL = "orders/sa/place";
  public static putOrderInEditModeURL = "orders/sa/edit/request";
  public static discardOrderEditURL = "orders/sa/edit/discard";
  public static amendOrderSubURL = "orders/sa/edit/amend";
  public static cancelOrderSubURL = "orders/sa/cancel";
  public static placeAlternativeOrderRequest = "orders/sa/select-alternatives";
  public static getUserOrderSubURL = "orders/sa/list";
  public static getUserLatestOrderURL = "orders/sa/latest";
  public static getSingleOrderById = "orders/sa/get";
  public static createOrderRating = "orders/sa/rate";
  public static serviceFeeAmount = "serviceFee/get"


  // Schedule Apis
  public static getScheduleSlots = "orders/sa/available-slots";
  public static getFirstAvlScheduleSlotStoreHomePage = "branches/home-page/first-available-slots";
  public static getFirstAvlScheduleSlot = "orders/sa/available-slots/first";
  public static getSlotAvailabilityById = "orders/sa/available-slots/availability";

  // Promo code Apis
  public static promoCodeValidation = "PromoCode/validate";

  // Loyalty program Apis
  public static syncLoyaltyProgram = "LoyaltyProgram/sync";
  public static checkCreditsLoyaltyProgram = "LoyaltyProgram/check";
  public static detachLoyaltyProgram = "LoyaltyProgram/detach";

  // Map Apis
  public static mapSearchPlacesSubUrl = "map/search";
  public static mapAutocompletePlacesSubUrl = "map/autocomplete";
  public static mapGetAddressSubUrl = "map/location-info";
  public static mapPlaceDetailsUrl = "map/place-details";

  // User Address
  public static createAddressSubUrl = "userAddress/create";
  public static updateUserAddress = "userAddress/update";
  public static userListOfAddressesSubUrl = "userAddress/list";
  public static setAddressPrimarySubUrl = "userAddress/set-primary";
  public static deleteUserAddress = "userAddress/delete";

  // Home Page
  public static storeHomePageSubUrl = "branches/home-page/items";

  // EWallet
  public static ewalletTransactions = "ewallet/get/transactions";
  public static ewalletBalance = "ewallet/get/balance";

}
