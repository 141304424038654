
    import Vue from "vue";
import { mapState } from "vuex";
  
    export default Vue.extend({

        name: "Recaptcha",
        props: {
            reset: {
                type: Boolean,
                default: false
            }
        },

        data: () => ({
            widgetId: null as number | null,
        }),

        computed: {
            ...mapState("partner", ["partnerSettings"]),
        },

        methods: {
            onVerify(response: string) {
                // Emit the token to the parent component
                this.$emit("verified", response);
            },
            onExpired() {
                this.$emit("expired");
            },
            onError() {
                this.$emit("error");
            },

            resetRecaptcha() {
                if (this.widgetId !== null && (window as any).grecaptcha) {
                    (window as any).grecaptcha.reset(this.widgetId);
                    this.$emit("resetCompleted");
                }
            },
        },

        watch: {
            reset(val: Boolean) {
                if(val = true) {
                    this.resetRecaptcha();
                }

            }
        },

        mounted() {
            // Ensure reCAPTCHA renders after the component is mounted
            if ((window as any).grecaptcha) {
                this.widgetId = (window as any).grecaptcha.render(this.$el.querySelector(".g-recaptcha"), {
                    sitekey: this.partnerSettings.googleRecaptchaKey,
                    callback: this.onVerify,
                    "expired-callback": this.onExpired,
                    "error-callback": this.onError,
                });
            }
        },
    });
