import { Module } from "vuex";
import request, { _axios } from "@/apiManager/_requestHelper";
import store, { RootState } from "..";
import { Apis } from "@/apiManager/Apis";
import PartnerSettings from "@/models/PartnerSettings";
import AppDataContent from "@/models/AppDataContent";

export interface PartnerSettingsState{
    partnerSettings: PartnerSettings;
    appDataContent: AppDataContent;
}

export default <Module<PartnerSettingsState, RootState>>{
    namespaced: true,
    state: {
        partnerSettings: {} as PartnerSettings,
        appDataContent: {} as AppDataContent,
    },

    mutations: {
        FETCH_PARTNER_SETTINGS(state, partnerSettings){
            state.partnerSettings = {} as PartnerSettings;
            state.appDataContent = {} as AppDataContent;
            state.partnerSettings = PartnerSettings.fetchPartnerSettingsObjectFromJson(partnerSettings.partnerSettings.settings);

            if(typeof partnerSettings.appDataContent != "undefined" && typeof partnerSettings.appDataContent.resources != "undefined")
                state.appDataContent = AppDataContent.fetchDataContentObjectFromJson(partnerSettings.appDataContent.resources);
        }
    },

    actions: {
        getPartnerSettings({commit}) {
            return request({
                url: Apis.customerSettings,
                method: "POST",
                data: { 
                    buildNumber: 0,
                    settingsHash: "",
                    appDataContentHash: "",
                    includeBranches: true 
                },
                commit: commit,
                loaderKey: "partnerSettingsLoading",
                successCallback: (partnerSettings: any) => {
                    commit("FETCH_PARTNER_SETTINGS", partnerSettings);
                }          
            })
        }
    }
}
  