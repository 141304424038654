
import Vue from 'vue'
export default Vue.extend({
    data: () => ({
        isSnackbarVisible: false as boolean
    }),

    computed: {
        snackbar: {
            get(): any {
                return this.$store.state["ui"].versionSnackbar;
            },
            set(value: any) {
                this.$store.commit("ui/versionSnackbar", value);
                
            },
        }
    },

    methods: {      
        reloadWindow() {
            window.location.reload();
        }
    }
})
