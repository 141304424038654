
import Vue from "vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import UIMessageDialog from "@/components/UI/Popup/UIMessageDialog.vue";
import MoneyFormat from "@/components/UI/Money/UIMoneyFormat.vue";
import CartComponent from "@/components/Cart/Cart.vue";
import AccountMenu from "@/components/Account/AccountMenu.vue";
import { ImageSettings } from "@/Settings/Settings";
import { EPartnerStoresStructureType } from "@/enums/EPartnerStoresStructureType";
import PartnerSettings from "@/models/PartnerSettings";
import ProfileSettingsDrawer from "./ProfileSettings/ProfileSettingsDrawer.vue";
import OrderType from "@/models/OrderType";
import User from "@/models/User";
import LoginCard from "@/views/Authentication/Authentication.vue";
import routerManager from "@/routerManagerNew/routerManager";
import { ERedirectTo } from "@/enums/ERedirectTo";
import Routes from "@/routerManager/routes";
import Cart from "@/models/Cart";
import SearchComponent from "./SearchComponent.vue";
import Stores from "@/views/Stores/Stores.vue";
import OrderTypeSelector from "@/components/Orders/OrderTypeSelector.vue";
import UserAddressSelector from "@/components/User/UserAddressSelector.vue";
import { logMetaPixelEvent } from "@/metaPixel/LogMetaPixelEvent";

export default Vue.extend({
  components: {
    MoneyFormat,
    Cart: CartComponent,
    AccountMenu,
    "profile-settings-drawer": ProfileSettingsDrawer,    
    "login-card": LoginCard,
    "ui-message-dialog": UIMessageDialog,
    "search-component": SearchComponent,
    "stores": Stores,
    "order-type-selector": OrderTypeSelector,
    "user-address-selector": UserAddressSelector,
  },
  
    props: {
      branchId: { required: false },
    },

  data: () => ({
    dialog: false as boolean,
    menuDrawer: false as boolean,
    cartDrawerState: false as boolean,
    itemsImageUrl: ImageSettings.baseItemsUrl,
    profileSettingsDrawerState: false as boolean,
    addressDialogState: false as boolean,
    authenticationDialog: false as boolean,
    deliveryOrderTypeInt: OrderType.deliveryOrderTypeInt,
    pickupOrderTypeInt: OrderType.pickupOrderTypeInt,
    eRedirectTo: ERedirectTo,
    storesPageRouteName: Routes.stores.name,
    logoutDialog: false as boolean,
    storesDialog: false as boolean,

    preferedOrderType: {typeInt: 0} as OrderType,
    scrollOffset: 0 as number,
  }),

  computed: {
    ...mapState("partner", ["partnerSettings", "appDataContent"]),
    ...mapState("store", ["selectedStoreDetails", "listOfStores"]),
    ...mapState("ui", ["isMobileViewDisplayed"]),
    ...mapState("schedule", ["storeHomePageFirstAvailableSlots"]),
    ...mapState("auth", ["preferredOrderType"]),

    ...mapGetters("auth", ["currentSelectedUser"]),
    ...mapGetters("cart", ["currentSelectedCartTotalQuantity"]),


    isPartnerStoreTypeIsSingleStore(): boolean {
      return (this.partnerSettings as PartnerSettings) && (this.partnerSettings as PartnerSettings).partnerStoresStructureType == EPartnerStoresStructureType.SingleStoreModel
    },
    
    isPartnerStoreTypeIsAgnosticOrMultiStore(): boolean {
      return (this.partnerSettings as PartnerSettings) 
        && ((this.partnerSettings as PartnerSettings).partnerStoresStructureType == EPartnerStoresStructureType.StoreAgnosticModel
          || (this.partnerSettings as PartnerSettings).partnerStoresStructureType == EPartnerStoresStructureType.MultiStoreSelectionModel)
    },

    cartSnackbar: {
      get(): any {
        return this.$store.state["ui"].cartSnackbar;
      },

      set(value: any) {
        this.$store.commit("ui/cartSnackbar", value);
      },
    },

    user(): User {
      return this.currentSelectedUser as User;
    },

    currentCart(): Cart {
      return  {} as Cart //this.user.listOfCarts.find(c => c.storeId == this.selectedStoreDetails.id) as Cart;
    },

    isStoreHomePageOrProductsPageIsOpened(): boolean {
      return true
    },

    // check if all stores button is displayed
    // if user is in store home page or products page => true
    isAllStoresBtnDisplayed(): boolean {
      return ((this as any).partnerSettings as PartnerSettings).partnerStoresStructureType == EPartnerStoresStructureType.MultiStoreSelectionModel
        && this.isStoreHomePageOrProductsPageIsOpened;
    },

    // check if the search bar is displayed
    isSearchBarDisplayed(): boolean {
      return this.$route.name != Routes.stores.name
    },

    // check if the search bar is displayed
    isCartBtnDisplayed(): boolean {
      return this.$route.name != Routes.stores.name
    },

    isStoresPageOpened(): boolean {
      return this.$route.name == 'storesPage'
    }


  },

  methods: {
    ...mapMutations("auth", ["SET_ORDER_TYPE"]),
    ...mapActions("auth", ["logout"]),
    ...mapMutations("product", ["SET_LAST_SUBCAT_SELECTED", "SET_LAST_CAT_SELECTED"]),


    logoutFunction() {
      this.logout();
      this.logoutDialog = false;
    },

    goToProfilePage() {
      routerManager.goToProfilePage();
    },

    goToStores() {
      this.storesDialog = true;
    },

  },

  mounted() {
    addEventListener("scroll", () => {            
      this.scrollOffset = document.documentElement.scrollTop;
    });
  },

  watch: {
    "preferredOrderType.typeInt"() {
      this.$forceUpdate()
    },


    cartDrawerState(newVal) {
      if(newVal == true) {
        logMetaPixelEvent('view_cart');
      }
      
      this.$emit("cartDrawer", newVal);
    },

  },

});
