import { Commit } from "vuex";
import axios, { AxiosRequestConfig } from "axios";
import { Snackbar } from "@/models/UI";
import moment from "moment";
import CryptoJS from "crypto-js";
import store from "@/store";

declare const config : any;

function generateSignature(data: any): string {
  // get signature
  return CryptoJS.HmacSHA256(JSON.stringify(data), config.VUE_APP_API_Secret || process.env.VUE_APP_API_Secret).toString();
}

function generateHeaders(signature: string, isHeaderContentMultipart: boolean = false, ): any{
  var headers = {
    "X-OSP-API-Key": "",
    "X-OSP-Signature": "",
    "content-type": "",
    "X-OSP-Content-Language": "",
    "X-OSP-Source": "",
    "X-OSP-Version": "",
    "X-OSP-Subdomain": "",
  };

  headers["X-OSP-API-Key"] = config.VUE_APP_API_Key || process.env.VUE_APP_API_Key;
  headers["X-OSP-Signature"] = signature;
  headers["content-type"] = isHeaderContentMultipart ? "multipart/form-data" : "application/json";
  headers["X-OSP-Content-Language"] = "EN";
  headers["X-OSP-Source"] = "WSA";
  headers["X-OSP-Version"] = config.VUE_APP_Version;
  headers["X-OSP-Subdomain"] = config.VUE_APP_Subdomain;

  return headers
}

let axiosConfig = {
  baseURL: config.VUE_APP_baseURL || process.env.VUE_APP_baseURL,
  timeout: 60 * 1000, // Timeout
};

export const _axios = axios.create(axiosConfig);

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent

    // if from > to
    if (
      typeof config.data != "undefined" &&
      typeof config.data.from != "undefined" &&
      typeof config.data.to != "undefined"
    )
      if (moment(config.data.from) > moment(config.data.to))
        return Promise.reject("To date must be greater or equal to From date");

    // if startDate > endDate
    if (
      typeof config.data != "undefined" &&
      typeof config.data.startDate != "undefined" &&
      typeof config.data.endDate != "undefined"
    )
      if (moment(config.data.startDate) > moment(config.data.endDate))
        return Promise.reject("To date must be greater or equal to From date");

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

// Pass commit as undefined to disable loading logic
export interface RequestConfiguration {
  commit?: Commit | undefined;
  method:
    | "get"
    | "GET"
    | "post"
    | "POST"
    | undefined;
  url: string;
  data?: any;
  cancelToken?: Object;
  includeData?: boolean;
  successCallback?: Function;
  loaderKey?: string;
  hideErrorToast?: boolean;
}

var ajaxRequestsCancellationTokens = <any>[];

export default function (config: RequestConfiguration) {
  var key = (
    config.url.indexOf("?") != -1
      ? config.url.substring(0, config.url.indexOf("?"))
      : config.url
  ) as string;
  if (ajaxRequestsCancellationTokens[key]) {
    ajaxRequestsCancellationTokens[key].cancel();
  }

  ajaxRequestsCancellationTokens[key] = axios.CancelToken.source();


  store.commit("loading/ENABLE_LOADING_STATE", config.loaderKey);

  return new Promise((resolve, reject) => {
    var axiosConfig: AxiosRequestConfig = {
      method: config.method,
      url: config.url,
      headers: generateHeaders(generateSignature(config.data)),
      data: config.data,
      cancelToken: ajaxRequestsCancellationTokens[key].token,
    };

    _axios(axiosConfig)
      .then((response) => {
       
        // request succeeded
        if (response.data.success === true) {
          if (typeof config.successCallback != "undefined")
            config.successCallback(response.data.data);

          if (config.commit && response.data.message)
            config.commit(
              "ui/snackbar",
              <Snackbar>{
                show: true,
                error: false,
                message: response.data.message,
              },
              { root: true }
            );

          delete ajaxRequestsCancellationTokens[key];
          resolve( config.includeData === true ? response.data : response.data.message);
        } 

        // an error occured
        else {

          if (typeof config.successCallback != "undefined") {
            config.successCallback(response.data.data);
          }

          if (config.commit && response.data.message && !config.hideErrorToast) {
            config.commit(
              "ui/snackbar",
              <Snackbar>{
                show: true,
                error: true,
                message:
                  response.data.message != null &&
                  response.data.message.length > 0
                    ? response.data.message
                    : "An error occured.",
              },
              { root: true }
            );
          }

          reject(response.data.message);
        }

        if(config.commit) {
          store.commit("loading/DISABLE_LOADING_STATE", config.loaderKey);
          const isNotLatestVersion = response.headers['x-is-latest-version'] == '0';

          config.commit(
            "ui/versionSnackbar",
            <Snackbar>{
              show: isNotLatestVersion,
              error: false             
            },
            { root: true }
          );
          
        } 
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          if (config.commit) {  
            
            // check response http code
            var httpStatusCode = error.response.status;

            // force reload the WSA
            if(httpStatusCode == 418) {
              window.location.href = `${window.location.origin}${window.location.pathname}?cache-bust=${new Date().getTime()}`
            }

            else {
              store.commit("loading/DISABLE_LOADING_STATE", config.loaderKey);
              if (error.response && !config.hideErrorToast) {
                config.commit(
                  "ui/snackbar",
                  <Snackbar>{
                    show: true,
                    error: true,
                    message:
                      error.response.data.message &&
                      error.response.data.message.length > 0
                        ? error.response.data.message
                        : "An error occured.",
                  },
                  { root: true }
                );
              }
            }
          }

          if (error.response && error.response.data)
            reject(config.includeData === true ? error.response.data : error.response.data.message);
          else if (error) {
            if (config.commit && !config.hideErrorToast) {
              config.commit(
                "ui/snackbar",
                <Snackbar>{
                  show: true,
                  error: true,
                  message:
                    error != null && error.length > 0
                      ? error
                      : "An error occured.",
                },
                { root: true }
              );
            }
            reject(error);
          }
        }
      });
  });
}
